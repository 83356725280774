<script lang="ts">
export default {
  inheritAttrs: false
}
</script>
<script setup lang="ts">
import { PhWarningCircle } from '@phosphor-icons/vue';
import { useField } from 'vee-validate';
import { computed, type Component } from 'vue';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';
import useFieldError from '@/composables/useFieldError';

export type Props = {
  name: string;
  modelValue?: string;
  icon?: Component;
  label?: string;
  placeholder?: string;
  helperText?: string;
  helperPosition?: 'left' | 'right';
  disabled?: boolean;
};

type Emit = {
  (e: 'update:modelValue', value: string): void;
};

const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: undefined,
    label: undefined,
    icon: undefined,
    placeholder: undefined,
    helperText: undefined,
    helperPosition: 'left',
    disabled: false,
  },
);

const emit = defineEmits<Emit>();

const attrsWithoutClass = useAttrsWithoutClass();

const {
  value: inputValue,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

function handleInputChange(e: Event) {
  handleChange(e);
  const input = e.target as HTMLInputElement;
  emit('update:modelValue', input.value);
}

const { hideError, showError } = useFieldError({ errorMessage, meta });

const helperPositionStyles = {
  left: 'self-start',
  right: 'self-end',
};

const helperPosition = computed(() => helperPositionStyles[props.helperPosition]);

</script>
<template>
  <div
    :class="$attrs.class"
    class="flex flex-col gap-2"
  >
    <label
      v-if="label"
      data-testid="label"
      class="text-xs"
      :for="name"
      :class="{ 'text-gray-400': disabled }"
    >
      {{ label }}
    </label>
    <div class="relative w-full">
      <component
        :is="icon"
        v-if="icon"
        class="absolute left-[17px] top-[13px] h-4 w-4"
        :class="[{ 'text-gray-300': disabled }]"
      />
      <input
        v-bind="attrsWithoutClass"
        :id="name"
        data-testid="input"
        class="w-full rounded-lg border px-4 py-3 text-sm leading-4 text-gray-900 caret-primary-400 placeholder:text-gray-300 hover:border-primary-500 focus:outline-primary-500 disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:border-gray-300"
        :class="[{
          'border-gray-300': hideError,
          'border-error-600 bg-error-25 pr-12 focus:outline-error-600': showError,
          'pl-12': icon,
        }]"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :value="inputValue"
        @blur="handleBlur"
        @change="handleInputChange"
      >
      <ph-warning-circle
        v-if="showError"
        class="absolute right-[17px] top-[13px] h-4 w-4 text-error-500"
      />
    </div>
    <span
      v-if="helperText && hideError"
      data-testid="helper"
      class="text-xs text-gray-400"
      :class="helperPosition"
    >
      {{ helperText }}
    </span>
    <div
      v-else-if="showError"
      data-testid="error-helper"
      class="flex gap-2 text-xs text-error-600"
    >
      <ph-warning-circle class="h-4 w-4" />
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>
